.page-container {
  border-radius: 7px;
  box-shadow: 1px 1px #f2f3f4;
  background: #f8f9f9;
  border: 1px solid;
  border-color: #e5e7e9;
  margin-bottom: 10px;
}

.container-header {
  color: #2C3531;
  margin-bottom: 50px;
}

.indent {
  font-weight: bold; /* Just for testing */
  width: 10%;
}

.container .row {
  margin-bottom: 0.1rem;
}

.container .row > [class^="col"] {
  padding-right: 0;
  padding-left: 0;
}